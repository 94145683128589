<template>
  <LayoutNew>
    <MDBLoading v-model="loadingSpinner" />
    <h1 class="text-center">User Settings</h1>
    <!-- form -->
    <MDBCard id="UserSettings" class="mb-2">
      <MDBCardHeader class="p-4">
        <h4 class="mb-0">General Information</h4>
      </MDBCardHeader>
      <MDBCardBody>
        <MDBContainer fluid tag="form">
          <MDBCol col="12" class="mt-1 mb-4">
            <MDBInput label="First Name" v-model="userDetail.firstName" />
          </MDBCol>
          <MDBCol col="12" class="mb-4">
            <MDBInput label="Last Name" v-model="userDetail.lastName" />
          </MDBCol>
          <MDBCol col="12">
            <MDBInput label="Password" type="password" v-model="form.password" />
          </MDBCol>
          <MDBCol col="12">
            <password-meter @score="onScore" :password="form.password" />
          </MDBCol>
          <MDBCol col="12" class="mt-2 mb-1">
            <MDBInput label="Confirm Password" type="password" v-model="confirmPassword" :helper="form.password && confirmPassword && !isSamePassword
              ? 'Password does not match!'
              : ''
              " />
          </MDBCol>
        </MDBContainer>
      </MDBCardBody>
      <MDBCardFooter class="d-flex justify-content-between align-items-center">
        <MDBBtn @click="submit" size="sm" color="primary" :disabled="isSubmitBtnDisabled"
          class="text-capitalize text-nowrap text-center">
          Save
        </MDBBtn>
        <MDBBtn tag="a" color="link" size="md"
          href="mailto:requests@enfinity.com?subject=I am requesting to change my payment information"
          class="text-capitalize px-0">
          Contact us to update your payment information
        </MDBBtn>
      </MDBCardFooter>
    </MDBCard>
    <!-- toast -->
    <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
      width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
      <template #title>
        {{ toastObject.title }}
      </template>
      {{ toastObject.message }}
    </MDBToast>
  </LayoutNew>
</template>

<script setup>
import {
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBLoading,
  MDBInput,
  MDBToast,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { computed, reactive, ref } from "vue";
import axios from "axios";
import { useUserDetailStore } from "@/store/userDetailStore";
import { storeToRefs } from "pinia";
import PasswordMeter from "vue-simple-password-meter";
import { useTitle } from "@vueuse/core";

useTitle("User Settings | Creator Shields");

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);
const form = reactive({
  firstname: userDetail.value.firstName,
  lastname: userDetail.value.lastName,
  password: "",
});

const toastObject = ref({
  state: false,
  message: "",
  color: "",
  icon: "",
  title: "",
});

let loadingSpinner = ref(false);
const submit = () => {
  let formData = new FormData();
  formData.append("firstname", form.firstname);
  formData.append("lastname", form.lastname);
  formData.append("password", form.password);
  loadingSpinner.value = true;

  axios
    .put("api/users/updateSettings", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      if (response.status == 200) {
        toastObject.value.title = "Success!";
        toastObject.value.state = true;
        toastObject.value.message = "Successfully submitted!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
        loadingSpinner.value = false;
      } else {
        toastObject.value.title = "Error!";
        toastObject.value.state = true;
        toastObject.value.message = "An error occurred during request";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        loadingSpinner.value = false;
      }
    })
    .catch(function (error) {
      toastObject.value.title = "Error!";
      toastObject.value.state = true;
      toastObject.value.message = "An error occurred during request" + error;
      toastObject.value.color = "danger";
      toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
      loadingSpinner.value = false;
    });
};

const confirmPassword = ref("");

const isSamePassword = computed(
  () =>
    form.password == confirmPassword.value &&
    form.password != "" &&
    confirmPassword.value != ""
);

const isSubmitBtnDisabled = computed(
  () =>
    !isSamePassword.value ||
    form.firstname == "" ||
    form.lastname == "" ||
    score.value < 3
);

const score = ref(null);
const onScore = (payload) => {
  score.value = payload.score;
};
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
}
</style>
