import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useUserDetailStore = defineStore("userDetail", () => {
  const userDetail = ref({
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    userTypeId: "",
    isDistributor: false,
    googleSheetLink: null,
    sheetName: null,
  });
  const isAdmin = computed(() => {
    return userDetail.value.userTypeId == 1;
  });
  const isSuperAdmin = computed(() => {
    return userDetail.value.userTypeId == 4;
  });
  const isUser = computed(() => {
    return userDetail.value.userTypeId == 2 || userDetail.value.userTypeId == 3;
  });
  return {
    userDetail,
    isAdmin,
    isSuperAdmin,
    isUser,
  };
});
