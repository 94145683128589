import { defineStore } from "pinia";
import { ref } from "vue";

export const useImpersonateStore = defineStore("impersonate", () => {
  const isImpersonating = ref(false);
  const userImpersonateDetail = ref({
    email: "",
    firstName: "",
    id: "",
    lastName: "",
    userTypeId: "",
    isDistributor: false,
    googleSheetLink: null,
  });
  return {
    isImpersonating,
    userImpersonateDetail,
  };
});
