import { HttpClient } from "../HttpClient";
import { isVVV } from "@/helpers/siteIdentifier";

/**
 * * Get Current
 */
export const GetCurrent = async (userId) => {
  
  try {
    const url = userId
      ? `api/users/Current?userId=${userId}&isVVV=${isVVV() ? "1" : "2"}`
      : `api/users/Current?isVVV=${isVVV() ? "1" : "2"}`;
    const { data } = await HttpClient.get(url);
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
