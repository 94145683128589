import { HttpClient } from "../HttpClient";
/**
 * * Get Deductions
 */
export const Logout = async () => {
	try {
		const { data } = await HttpClient.delete("api/users/logout");
		return await Promise.resolve(data);
	} catch (err) {
		return Promise.reject(err);
	}
};
