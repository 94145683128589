<template>
  <div class="LayoutNew">
    <!-- top navbar -->
    <MDBNavbar id="mainNavbar" container class="pe-3 sticky-top" style="padding-left: 11.6px;">
      <MDBBtn v-if="!hideSidenav" color="tertiary" aria-controls="#mainSidenav" @click="mainSidenav = !mainSidenav"
        id="mainSidenavTogglerNavbar" style="padding-left: 0;">
        <MDBIcon icon="bars" />
      </MDBBtn>
      <MDBNavbarNav right>
        <MDBNavbarItem>
          <MDBDropdown btnGroup align="lg-end" v-model="userDropdown" id="userDropdown">
            <MDBDropdownToggle tag="a" class="nav-link d-flex align-items-center" @click="userDropdown = !userDropdown"
              color="dark">
              <img :src="imageUrl" class="img-fluid rounded-circle logo2" alt="user-image" />
              <div class="d-flex flex-column justify-content-center align-items-center mx-2">
                <div class="text-capitalize user-font" style="font-size: 14.5px;">
                  {{ userDetail.firstName }}
                  {{ userDetail.lastName }}
                </div>
                <span style="color: var(--blue);text-transform: uppercase;font-size: 11px;font-weight: bold;">
                  {{ setUserType(userDetail.userTypeId) }}
                </span>
              </div>
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem class="nav-link-item" href="#/UserSettings">
                User Settings
              </MDBDropdownItem>
              <MDBDropdownItem class="nav-link-item" href="#" @click="handleLogout">
                Logout
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBNavbar>
    <!-- side navabar -->
    <template v-if="!hideSidenav">
      <MDBSideNav v-model="mainSidenav" id="mainSidenav" :mode="sideNavMode" contentSelector="#content">
        <div class="title">
          <MDBBtn color="tertiary" aria-controls="#mainSidenav" @click="mainSidenav = !mainSidenav"
            id="mainSidenavTogglerSidenav">
            <MDBIcon icon="bars" />
          </MDBBtn>
          <div class="w-100 d-flex justify-content-center pe-5">
            <img @click="toDashboard" :src="logoUrl" alt="creator-shield-logo"
              class="rounded-circle img-fluid object-fit-contain logo" />
          </div>
        </div>
        <hr class="m-0" />
        <MDBSideNavMenu scrollContainer>
          <MDBSideNavItem>
            <MDBSideNavLink to="/Dashboard">
              <MDBIcon icon="home" class="fas fa-fw me-3" />
              Home
              <hr class="mb-0" />
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink to="/Assets">
              <MDBIcon icon="heart" class="fas fa-fw me-3" />
              Assets
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink to="/AssetLabels">
              <MDBIcon icon="sticky-note" class="fas fa-fw me-3" />
              Asset Labels
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem v-if="isUser">
            <MDBSideNavLink :to="adjustmentsURL">
              <em class="fas fa-hand-holding-usd me-3" />
              Adjustments
            </MDBSideNavLink>
          </MDBSideNavItem>
          <template v-if="isAdmin || isSuperAdmin">
            <MDBSideNavItem collapse icon="photo-video" title="CMS" class="nav-collapsible">
              <MDBSideNavItem>
                <MDBSideNavLink to="/Collections" class="py-3">
                  <MDBIcon icon="list" class="fas fa-fw me-3" />
                  Collections
                </MDBSideNavLink>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <MDBSideNavLink to="/Whitelist" class="py-3">
                  <MDBIcon icon="flag" class="fas fa-fw me-3" />
                  Whitelist
                </MDBSideNavLink>
              </MDBSideNavItem>
            </MDBSideNavItem>
            <MDBSideNavItem collapse icon="shield-alt" title="Rights Management">
              <MDBSideNavItem>
                <MDBSideNavLink to="/Copies" class="py-3">
                  <MDBIcon icon="clone" class="fas fa-fw me-3" />
                  Copies
                </MDBSideNavLink>
              </MDBSideNavItem>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <MDBSideNavLink to="/DailyVideoUpdate/Management">
                <MDBIcon icon="cogs" class="fas fa-fw me-3" />
                Video Update Mgmt
              </MDBSideNavLink>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <MDBSideNavLink to="/DailyVideoUpdate">
                <MDBIcon icon="bell" class="fas fa-fw me-3" />
                Daily Video Updates
              </MDBSideNavLink>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <MDBSideNavLink to="/VideoUpdateReport">
                <MDBIcon icon="chart-line" class="fas fa-fw me-3" />
                Video Update Report
              </MDBSideNavLink>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <MDBSideNavLink to="/UserManagement">
                <MDBIcon icon="user-circle" class="fas fa-fw me-3" />
                User Management
              </MDBSideNavLink>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <MDBSideNavLink to="/UploadAnalytics">
                <MDBIcon icon="file" class="fas fa-fw me-3" />
                Upload Analytics
              </MDBSideNavLink>
            </MDBSideNavItem>
          </template>
          <template v-if="isUser">
            <MDBSideNavItem v-if="userDetail.userTypeId === 3" class="mt-auto">
              <MDBSideNavLink :to="userDetail.googleSheetLink" target="_blank">
                <em class="fas fa-file-excel me-3" />
                Distributor Google Sheet
              </MDBSideNavLink>
            </MDBSideNavItem>
            <MDBSideNavItem :class="{ 'mt-auto': userDetail.userTypeId === 2 }">
              <MDBSideNavLink to="https://intercom.help/enfinity/en/collections/3116620-creatorshield" target="_blank">
                <em class="fas fa-life-ring me-3" />
                Help Center
              </MDBSideNavLink>
            </MDBSideNavItem>
          </template>
        </MDBSideNavMenu>
      </MDBSideNav>
    </template>
    <!-- dashboard container -->
    <MDBContainer id="content">
      <MDBAlert v-if="hideSidenav" class="impersonate-banner" static>
        <i class="fas fa-user-secret me-3"></i>
        <p>
          VIEWING as
          <strong>{{ lastName }}, {{ firstName }}</strong> [IMPERSONATING]
        </p>
        <MDBTooltip v-model="exitUserManagement">
          <template #reference>
            <i class="fas fa-backspace" @click="exitImpersonation"></i>
          </template>
          <template #tip> Exit Impersonation </template>
        </MDBTooltip>
      </MDBAlert>
      <slot></slot>
    </MDBContainer>
  </div>
</template>

<script setup>
import {
  MDBNavbar,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBBtn,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
  MDBAlert,
  MDBTooltip,
} from "mdb-vue-ui-kit";
import { computed, onMounted, ref, watch, watchEffect, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { useUserDetailStore } from "../../store/userDetailStore";
import { GetCurrent } from "../../services/Users/GetCurrent";
import { Logout } from "../../services/Logout/Logout";
import { storeToRefs } from "pinia";
import { useImpersonateStore } from "../../store/impersonateStore";
import { useTimeout } from "@vueuse/core";

const isMobile = ref(window.innerWidth <= 767);
const sideNavMode = ref(isMobile.value ? 'over' : 'side');
const mainSidenav = ref(isMobile.value ? false : true);

const updateResolution = () => {
  isMobile.value = window.innerWidth <= 767;
};

watchEffect(() => {
  sideNavMode.value = isMobile.value ? 'over' : 'side';
  mainSidenav.value = isMobile.value ? false : true;
});

onMounted(() => {
  window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateResolution);
});

const logo = require("@/assets/CSD-logo-black.png");
const logoUrl = computed(() => logo);

const router = useRouter();
const toDashboard = () => {
  router.push("/Dashboard");
};

const adjustmentsURL = ref("");

const defaultImage = require("@/assets/user-default.png");
const imageUrl = computed(() => defaultImage);

const userDropdown = ref(false);

const userDetailStore = useUserDetailStore();
const { userDetail, isAdmin, isUser, isSuperAdmin } = storeToRefs(userDetailStore);
onMounted(async () => {
  const response = await GetCurrent();
  userDetail.value = response;
  adjustmentsURL.value = `/Users/${response.id}/Adjustments`;
});

const handleLogout = () => {
  Logout().then(() => {
    router.push("/Login");
  });
};
const setUserType = (userTypeId) => {
  switch (userTypeId) {
    case 1:
      return "Admin";
    case 2:
      return "User";
    case 3:
      return "Distributor";
    case 4:
      return "Super Admin";
    default:
      return "-";
  }
}

const exitUserManagement = ref(false);
const { hideSidenav, exitImpersonation, firstName, lastName } =
  useImpersonate();
function useImpersonate() {
  const impersonateStore = useImpersonateStore();
  const { isImpersonating, userImpersonateDetail } =
    storeToRefs(impersonateStore);
  const { firstName, lastName } = userImpersonateDetail.value;
  const hideSidenav = ref(false);
  const { start, ready } = useTimeout(3000, { controls: true });
  onMounted(async () => {
    if (isImpersonating.value) {
      mainSidenav.value = false;
      adjustmentsURL.value = `/Users/${userImpersonateDetail.value.id}/Adjustments`;
      start();
    }
  });

  watch(ready, (newVal) => {
    hideSidenav.value = newVal && isImpersonating.value;
  });

  const exitImpersonation = () => {
    isImpersonating.value = false;
    router.push({ name: "UserManagement" });
  };

  return {
    hideSidenav,
    exitImpersonation,
    firstName,
    lastName,
  };
}
</script>

<style>
:root {
  --white: #ffffff;
  --primary: #fc5818;
  --secondary: #e2e6ef;
  --blue: #1f87fc;
  --accent: #02061b;
}

.sidenav-link:hover {
  background-color: var(--primary) !important;
  color: white !important;
}

.tooltip-inner {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--primary);
  font-size: 12px;
  padding: 3px 12px;
}
</style>

<style scoped>
.LayoutNew {
  background-color: var(--secondary);
  font-family: "Poppins", sans-serif;
  overflow-y: auto;
}

#mainNavbar {
  background-color: white;
  color: var(--primary);
}

#mainSidenavTogglerNavbar,
#mainSidenavTogglerSidenav {
  font-size: 16px;
  color: var(--primary);
  box-shadow: none;
}

:global(#mainSidenav.sidenav) {
  background-color: white;

  .title {
    height: 58px;
    display: flex;
    justify-content: space-between;
  }

  .title .logo {
    padding: 10px;
    height: 58px;
  }
}

:global(#mainSidenav + .sidenav-backdrop) {
  background-color: rgba(0, 0, 0, 0.4);
}

:global(#mainSidenav .sidenav-menu) {
  color: var(--accent);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.logo2 {
  width: 40px;
}

.nav-link {
  color: var(--accent);
  padding: 0;
}

:deep(#mainSidenav .nav-link-item:hover) {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

.sidenav-link {
  border-radius: 0;
}

.sidenav-link.active {
  background-color: var(--primary);
  color: white;
  cursor: pointer;
}

#userDropdown {
  box-shadow: none;
}

#content {
  font-family: "Poppins", sans-serif;
  background-color: var(--secondary);
  margin-inline: auto !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-block: 48px;
  height: calc(100vh - 60px);
}

.impersonate-banner {
  display: flex;
  align-items: center;
  color: var(--primary);
  background-color: var(--accent);
}

.impersonate-banner p {
  margin-bottom: 0;
  margin-right: auto;
}

.impersonate-banner :deep(span) {
  cursor: pointer;
}
</style>
